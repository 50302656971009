<template>
    <div class="box">
        <button class="join" @click="createapi">创建及API相关</button>
        <button class="join" @click="joinChannel">加入频道</button>
        <button class="join" @click="connectSignalr">SignalR连接</button>
        <button class="join" @click="sendMessageinfo('18482133094-HiTeahCC',null,null,'这是测试消息',channelNum)">发送消息</button>
        <div><span>教室编号：</span><span style="color:#ff0000;font-weight:bold">{{channelNum}}</span></div>
        <div v-for="item in list.messages">这是收到的信息：{{ item.Text }}</div>
    </div>
</template>
<script>
    import { signalRInfo, sendMessage, onConnected, data, } from "../plugin/signalr.js";
    import axios from 'axios';
    export default {
        data() {
            return {
                username: "18482133094-HiTeahCC",
                userId: "18482133094",
                newMessage: "",
                messages: [],
                ready: false,
                myConnectionId: "",
                //signalR相应数据list
                channelApi: "",
                channelNum: "",
                channelRestapi: "",
                channelUrl: "",
                access_Token: "",
                newtoken: "",
                newtoken2: "",
                channelUrl2: "",
                //发送消息
                messageContent: "这是测试消息",
                list: [],
            };
        },
        methods: {
            //创建IM及获取频道
            createapi() {
                let apiUrl = "https://api2.teammodel.cn/channel/create";
                let TMDID = "18482133094";
                let chanpid = "HiTeachCC";
                let Pin = "8888";
                let access_token =
                    "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6Il9DSkFPdHlzWVZtNXhjMVlvSzBvUTdxeUJDUSJ9.eyJhdWQiOiI3MjY0MzcwNC1iMmU3LTRiMjYtYjg4MS1iZDU4NjVlN2E3YTUiLCJpc3MiOiJodHRwczovL2xvZ2luLnBhcnRuZXIubWljcm9zb2Z0b25saW5lLmNuLzQ4MDdlOWNmLTg3YjgtNDE3NC1hYTViLWU3NjQ5N2Q3MzkyYi92Mi4wIiwiaWF0IjoxNjIwNjMwMDI4LCJuYmYiOjE2MjA2MzAwMjgsImV4cCI6MTYyMDYzMzkyOCwiYWlvIjoiNDJKZ1lNanJtWEE3KzgvUENuM1dGZS9tdlgxaER3QT0iLCJhenAiOiJjNzMxN2Y4OC03Y2VhLTRlNDgtYWM1Ny1hMTYwNzFmN2I4ODQiLCJhenBhY3IiOiIxIiwib2lkIjoiZDQ5YmI3ODItNmRjMi00OTM5LTg2NzgtZDMzYzI2NzE5YmY5IiwicmgiOiIwLkFBQUF6LWtIU0xpSGRFR3FXLWRrbDljNUs0aF9NY2ZxZkVoT3JGZWhZSEgzdUlRQkFBQS4iLCJyb2xlcyI6WyJJRVMiXSwic3ViIjoiZDQ5YmI3ODItNmRjMi00OTM5LTg2NzgtZDMzYzI2NzE5YmY5IiwidGlkIjoiNDgwN2U5Y2YtODdiOC00MTc0LWFhNWItZTc2NDk3ZDczOTJiIiwidXRpIjoiU3RteEN5NHFsa0tCTTJwclFpTU5BUSIsInZlciI6IjIuMCJ9.mkFRLPF1T2w5uCcphKX7ZDKilIJjBoE8gw4yjK5Jffwmo434AS21s9NnLWRiAqVpMXordVPGHoAKwEfJFQ4xQWxTCyxLc9JAlONWKU7-AsjQK9if8edZqIbroh_jB0P7Sxz7UeGLBaNRxgF10_0Sr7Ew7npS4YksTqgbKDnxzYFrvRtxF5eAP3Uwix611qA0v10YJl6zsJ11J9N0_raRWoycdpI53aGBAICziWJsAyogwUdLCb2x-9BG4fQOMRR7eFnjReZ8r9E2G1xpwBvdiDUs-dZ-gVhp8gkAu5u9bUSLOJC6SUhAw97OIZvFPQh68qDxkR_wA2zREGHpxnpEQw";
                this.$api.createapi({
                    client_name: chanpid,
                    user_id: TMDID,
                    pin: Pin,
                    local_ip: "",
                    channel: "",
                    school_code: "",
                }).then((response) => {
                    console.log(response);
                    this.access_Token = response.access_token;
                    this.channelApi = response.channel_api;
                    this.channelNum = response.channel_num;
                    sessionStorage.setItem('newClassnum', response.channel_num)
                    this.channelRestapi = response.channel_rest_api;
                    this.channelUrl = response.channel_url;
                    console.log("访问成功");
                }).catch((error) => {
                    console.log(error, "访问失败");
                });
            },
            //加入频道
            joinChannel() {
                let apiUrl = "https://api2.teammodel.cn/channel/join";
                let TMDID = "18482133094";
                let Pin = "8888";
                let access_token =
                    "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6Il9DSkFPdHlzWVZtNXhjMVlvSzBvUTdxeUJDUSJ9.eyJhdWQiOiI3MjY0MzcwNC1iMmU3LTRiMjYtYjg4MS1iZDU4NjVlN2E3YTUiLCJpc3MiOiJodHRwczovL2xvZ2luLnBhcnRuZXIubWljcm9zb2Z0b25saW5lLmNuLzQ4MDdlOWNmLTg3YjgtNDE3NC1hYTViLWU3NjQ5N2Q3MzkyYi92Mi4wIiwiaWF0IjoxNjIwNjMwMDI4LCJuYmYiOjE2MjA2MzAwMjgsImV4cCI6MTYyMDYzMzkyOCwiYWlvIjoiNDJKZ1lNanJtWEE3KzgvUENuM1dGZS9tdlgxaER3QT0iLCJhenAiOiJjNzMxN2Y4OC03Y2VhLTRlNDgtYWM1Ny1hMTYwNzFmN2I4ODQiLCJhenBhY3IiOiIxIiwib2lkIjoiZDQ5YmI3ODItNmRjMi00OTM5LTg2NzgtZDMzYzI2NzE5YmY5IiwicmgiOiIwLkFBQUF6LWtIU0xpSGRFR3FXLWRrbDljNUs0aF9NY2ZxZkVoT3JGZWhZSEgzdUlRQkFBQS4iLCJyb2xlcyI6WyJJRVMiXSwic3ViIjoiZDQ5YmI3ODItNmRjMi00OTM5LTg2NzgtZDMzYzI2NzE5YmY5IiwidGlkIjoiNDgwN2U5Y2YtODdiOC00MTc0LWFhNWItZTc2NDk3ZDczOTJiIiwidXRpIjoiU3RteEN5NHFsa0tCTTJwclFpTU5BUSIsInZlciI6IjIuMCJ9.mkFRLPF1T2w5uCcphKX7ZDKilIJjBoE8gw4yjK5Jffwmo434AS21s9NnLWRiAqVpMXordVPGHoAKwEfJFQ4xQWxTCyxLc9JAlONWKU7-AsjQK9if8edZqIbroh_jB0P7Sxz7UeGLBaNRxgF10_0Sr7Ew7npS4YksTqgbKDnxzYFrvRtxF5eAP3Uwix611qA0v10YJl6zsJ11J9N0_raRWoycdpI53aGBAICziWJsAyogwUdLCb2x-9BG4fQOMRR7eFnjReZ8r9E2G1xpwBvdiDUs-dZ-gVhp8gkAu5u9bUSLOJC6SUhAw97OIZvFPQh68qDxkR_wA2zREGHpxnpEQw";
                let channelInfo = this.channelNum;
                this.$api.joinclass({
                    client_name: "HiTeachCC",
                    user_id: TMDID,
                    pin: Pin,
                    channel: channelInfo,
                })
                    .then((response) => {
                        console.log("加入成功");
                        console.log(response);
                        this.newtoken = response.access_token;
                        this.channelUrl = response.channel_url;
                    })
                    .catch((error) => {
                        console.log("加入失败");
                    });
            },
            //singnalR连接
            connectSignalr() {
                let accessToken = this.newtoken;
                let channel_api = this.channelApi;
                let classNum = this.channelNum;
                let channel_url = this.channelUrl;
                signalRInfo(channel_url, accessToken);
            },
            //发送消息
            sendMessageinfo(username, to, groupArr, text, classnum) {
                var apiUrl = this.channelApi + "/messages";
                var usernameInfo = username;
                var toArr = to;
                var groupData = groupArr;
                var onMessage = text;
                var classNum = classnum;
                this.myConnectionId = data.myConnectionId;
                this.$api.tomessage(apiUrl, {
                    connectionId: data.myConnectionId,
                    sender: usernameInfo,
                    to: toArr,
                    groupname: groupData,
                    text: JSON.stringify({
                        MT: "N",
                        TM: Math.floor(Date.now()),
                        PL: onMessage,
                    }),
                })
                    .then((res) => {
                        console.log(res, "消息发送成功");
                        console.log(onMessage, "发送的内容");
                        console.log(data, "data的内容");
                        this.list = data;
                    })
                    .catch((res) => {
                        console.log(res, "消息发送失败");
                    });
            },
        },
    };
</script>
<style scoped>
    .box {
        width: 800px;
        height: 200px;
        margin: 0 auto;
        border: 1px solid red;
    }

    .join {
        width: 70px;
        height: 40px;
        font-size: 12px;
        border:1px solid #c4c4c4;
        margin-right:15px;
        padding:3px;
    }

</style>
